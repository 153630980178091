export default function debounce(callback, delay) {
  let timerId = 0;

  return function (...params) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => callback.apply(null, params), delay);
  }
}
export default function pluralize(quantity, options) {
  /**
   * options: [
   *    0 -> товар
   *    1 -> товара
   *    2 -> товаров
   * ]
   */
  const remainder = (quantity %= 100, quantity > 14 ? quantity % 10 : quantity);
  return options[remainder === 1 ? 0 : remainder > 1 && remainder < 5 ? 1 : 2];
}
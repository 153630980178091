import debounce from './debounce';

const callbacks = [];

const SCROLL_HANDLER_DELAY = 150;

const onWindowScrollHandler = debounce((event) => {
  if (callbacks.length === 0) {
    return undefined;
  }

  if (event.target.scrollTop >= (0.95 * document.body.scrollHeight - document.body.offsetHeight)) {
    callbacks.forEach((callback) => callback.call(null));
  }
}, SCROLL_HANDLER_DELAY);

document.body.addEventListener('scroll', onWindowScrollHandler);

export default function onBottomReached(callback) {
  const existedCallbackIndex = callbacks.indexOf(callback);
  const isCallbackExists = existedCallbackIndex > -1;
  const callbackIndex = isCallbackExists ? existedCallbackIndex : callbacks.length;

  if (!isCallbackExists) {
    callbacks.push(callback);
  }

  return function unsubscribe() {
    callbacks.splice(callbackIndex, 1);
  };
}
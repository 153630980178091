import formatPrice from './format-price';
import pluralize from './pluralize';
import request from './request';

export default function init() {
  const cartFormElem = document.querySelector('.cart__form');

  if (cartFormElem == null) {
    return;
  }

  const totalQuantityElem = cartFormElem.querySelector('.cart__total-quantity');
  const totalPriceElem = cartFormElem.querySelector('.cart__total-price');

  const inputEvent = new Event('input', { bubbles: true, cancelable: true });

  const products = [];
  const productsMap = {};

  function updateProductQuantity(productId, quantity, priceElem) {
    const product = products[productsMap[productId]];
    product.quantity = quantity;
    product.totalPrice = quantity * product.price;

    priceElem.textContent = formatPrice(product.totalPrice);

    updateTotal();
  }

  function updateTotal() {
    const { totalQuantity, totalPrice } = products.reduce((res, product) => {
      res.totalQuantity += product.quantity;
      res.totalPrice += product.totalPrice;

      return res;
    }, { totalPrice: 0, totalQuantity: 0 });

    totalQuantityElem.textContent = `${totalQuantity} ${pluralize(totalQuantity, ['товар', 'товара', 'товаров'])}`
    totalPriceElem.textContent = formatPrice(totalPrice);
  }

  for (const productElem of cartFormElem.querySelectorAll('.cart__product')) {
    const {
      productId,
      productFractionalCount,
      productPrice
    } = productElem.dataset;
    const quantityInputElem = productElem.querySelector('.cart__product-amount-input');
    const quantityDecElem = productElem.querySelector('.cart__product-amount-change--dec');
    const quantityIncElem = productElem.querySelector('.cart__product-amount-change--inc');
    const priceElem = productElem.querySelector('.cart__product-price');
    const deleteElem = productElem.querySelector('.cart__product-control--delete');

    const productIdInt = parseInt(productId, 10);
    const productQuantity = parseInt(quantityInputElem.value, 10);
    const productFractionalCountInt = parseInt(productFractionalCount, 10);

    const newProductIndex = products.push({
      id: productIdInt,
      price: productPrice,
      quantity: productQuantity,
      totalPrice: productPrice * productQuantity
    });

    quantityInputElem.addEventListener('input', ((productId) => (event) => {
      const newVal = parseInt(event.currentTarget.value, 10);

      updateProductQuantity(productId, newVal, priceElem);
    })(productIdInt));

    quantityDecElem.addEventListener('click', (event) => {
      const productQuantity = parseInt(quantityInputElem.value, 10);

      quantityInputElem.value = Math.max(0, productQuantity - productFractionalCountInt);

      quantityInputElem.dispatchEvent(inputEvent);
    });

    quantityIncElem.addEventListener('click', (event) => {
      const productQuantity = parseInt(quantityInputElem.value, 10);

      quantityInputElem.value = productQuantity + productFractionalCountInt;

      quantityInputElem.dispatchEvent(inputEvent);
    });

    deleteElem.addEventListener('click', ((productId) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      request('delete/', { id: productId }, () => location.reload());
    })(productIdInt));

    productsMap[productId] = newProductIndex - 1;
  }
}
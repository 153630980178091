function initTabs(tabsElem) {
  const tabs = {};
  let activeTab = '';

  for (const content of tabsElem.querySelectorAll('.tab-content')) {
    const { tabId } = content.dataset;

    if (typeof tabs[tabId] === 'undefined') {
      tabs[tabId] = { content };
    }

    if (content.classList.contains('tab-content--active')) {
      activeTab = tabId;
    }
  }

  for (const link of tabsElem.querySelectorAll('.tab-item a')) {
    const { tabId } = link.dataset;

    if (tabs[tabId] != null) {
      tabs[tabId].link = link;

      link.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (activeTab !== tabId && tabs[tabId] != null) {
          if (activeTab != null) {
            tabs[activeTab].link.parentElement.classList.remove('active');
            tabs[activeTab].content.classList.remove('tab-content--active');
          }

          tabs[tabId].link.parentElement.classList.add('active');
          tabs[tabId].content.classList.add('tab-content--active');

          activeTab = tabId;
        }
      });
    }
  }
}

export default function init() {
  for (const tabElem of document.querySelectorAll('.tabs')) {
    initTabs(tabElem);
  }
}
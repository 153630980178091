import formatPrice from './format-price';

export default function init() {
  const productOrderForm = document.querySelector('.product__order-form form');

  if (!productOrderForm) {
    return false;
  }

  const {
    fractional_count: fractionalCountInput,
    quantity: quantityInput,
    sku_id: skuIdInput,
    submit: submitBtn
  } = productOrderForm.elements;

  const fractionalCount = parseInt(fractionalCountInput.value, 10) || 1;
  const skus = {};

  for (const sku of (skuIdInput instanceof HTMLInputElement ? [skuIdInput] : skuIdInput)) {
    skus[sku.value] = {
      price: parseInt(sku.dataset.price, 10) || 0,
      priceOld: parseInt(sku.dataset.priceOld, 10) || 0,
      available: sku.dataset.disabled === 'false',
      imageId: parseInt(sku.dataset.imageId, 10) || 0
    };
  }

  const priceLabel = productOrderForm.querySelector('.product__price:not(.product__price--old)');
  const priceLabelOld = productOrderForm.querySelector('.product__price--old');

  const inputEvent = new Event('input', { bubbles: true, cancelable: true });

  for (const quantityCtrl of productOrderForm.querySelectorAll('button.product__amount-change')) {
    const step = (quantityCtrl.classList.contains('product__amount-change--dec') ? -1 : 1) * fractionalCount;

    quantityCtrl.addEventListener('click', ((step) => () => {
      const currentValue = parseInt(quantityInput.value, 10);
      const newQuantity = Math.max(0, (currentValue === 1 && fractionalCount !== 1 ? 0 : currentValue) + step);

      quantityInput.value = newQuantity;
      quantityInput.dispatchEvent(inputEvent);
    })(step));
  }

  quantityInput.addEventListener('input', (event) => {
    const { available, price, priceOld, imageId } = skus[skuIdInput.value];
    const quantity = parseInt(event.currentTarget.value, 10);

    if (isNaN(quantity)) {
      return;
    }

    priceLabel.textContent = formatPrice(quantity * price);

    if (priceOld && priceOld !== price) {
      priceLabelOld.textContent = formatPrice(quantity * priceOld);
    } else {
      priceLabelOld.textContent = '';
    }

    if (submitBtn) {
      submitBtn.disabled = !available;
    }

    if (imageId != null && window.carousels && window.carousels.length > 0) {
      window.carousels[0].scrollToId(imageId);
    }
  });

  for (const skuIdRadioElement of (skuIdInput instanceof HTMLInputElement ? [skuIdInput] : skuIdInput)) {
    skuIdRadioElement.addEventListener('change', () => quantityInput.dispatchEvent(inputEvent));
  }

  quantityInput.dispatchEvent(inputEvent);
}
/**
 * 
 * @param {string} url Request url
 * @param {object} body Request body, if present Post-request will be made
 * @param {function} onSuccess On success callback
 * @param {function} onError On error callback
 */
export default function request(url, body, onSuccess, onError) {
  const req = new XMLHttpRequest();
  const hasBody = body != null && typeof body === 'object';
  const onSuccessCallback = typeof onSuccess === 'function' ? onSuccess : console.log;
  const onErrorCallback = typeof onSuccess === 'function' ? onError : console.error;

  req.addEventListener('readystatechange', ({ target }) => {
    const { readyState, responseText, status } = target;

    if (readyState === 4) {
      if (status >= 200 && status < 300) {
        try {
          const data = JSON.parse(responseText);
          onSuccessCallback(data);
        } catch (err) {
          onErrorCallback(err);
        }
      } else {
        const err = new Error(responseText);
        err.name = status.toSttring();
        onErrorCallback(err);
      }
    }
  });

  req.addEventListener('error', onErrorCallback);

  req.open(hasBody ? 'POST' : 'GET', url);

  req.send(hasBody ?
    new URLSearchParams(Object.keys(body).map((key) => ([key, body[key]]))) :
    undefined
  );
}
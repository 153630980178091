import carousel from './carousel';
import cart from './cart';
import productOrderForm from './product-order-form';
import productPhotosLightbox from './product-photos-lightbox';
import productPhotosVariants from './product-photos-variants';
import productsInfiniteScroll from './products-infinite-scroll';
import share from './share';
import sidebar from './sidebar';
import subheader from './subheader';
import tabs from './tabs';

document.addEventListener('DOMContentLoaded', () => {
  cart();
  carousel();
  productOrderForm();
  productPhotosLightbox();
  productPhotosVariants();
  productsInfiniteScroll();
  share();
  sidebar();
  subheader();
  tabs();
});
export default function init() {
  const supheaderElement = document.querySelector(".supheader");
  const headerElement = document.querySelector("header");
  const subheaderElement = document.querySelector(".subheader");

  const supPlusHeaderHeight =
    supheaderElement.offsetHeight + headerElement.offsetHeight;

  let ticking = false;

  window.addEventListener("scroll", event => {
    const scrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (scrollPosition > supPlusHeaderHeight) {
          subheaderElement.classList.add("subheader--fixed");
        } else {
          subheaderElement.classList.remove("subheader--fixed");
        }
        ticking = false;
      });

      ticking = true;
    }
  });
}

export default function init() {
  let sidebarElem = document.querySelector('.sidebar');
  let showSidebarElem = document.querySelector('.supheader__sidebar');
  let closeSidebarElem = sidebarElem.querySelector('.sidebar__close');

  showSidebarElem.addEventListener('click', () => {
    sidebarElem.classList.add('sidebar--visible');
  });

  closeSidebarElem.addEventListener('click', () => {
    sidebarElem.classList.remove('sidebar--visible');
  });
}
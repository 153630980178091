import onBottomReached from './on-bottom-reached';

const container = document.createElement('div');

function fetchProducts(page) {
  return fetch(window.location.pathname + `?page=${page}`)
    .then((res) => {
      if (res.ok) {
        return res.text();
      } else {
        const err = new Error(res.statusText);
        err.name = res.status.toString();

        throw err;
      }
    })
    .then((content) => {
      container.innerHTML = content;

      return (container.querySelector('.catalog-section > .catalog-items') || { innerHTML: '' }).innerHTML;
    })
    .catch(console.error);
}

/**
 * Should be in sync with 'products_per_page' param 
 * on this page http://fittonia.ru/webasyst/shop/?action=plugins#/hidset 
 */
const DEFAULT_ITEMS_PER_PAGE = 60;

let onBottomReachedUnsubscribe = null;

function categoryHasMoreProducts(container, currentPage, itemsPerPage) {
  return container.querySelectorAll('.catalog-item').length >= currentPage * itemsPerPage;
}

export default function init() {
  const catalogItems = document.querySelector('.catalog-section > .catalog-items');

  if (catalogItems == null) {
    return;
  }

  let currentPage = 1;
  let isLoading = false;
  let hasMoreProducts = categoryHasMoreProducts(catalogItems, currentPage, DEFAULT_ITEMS_PER_PAGE);

  if (!hasMoreProducts) {
    return;
  }

  if (typeof onBottomReachedUnsubscribe === 'function') {
    onBottomReachedUnsubscribe();
  }

  onBottomReachedUnsubscribe = onBottomReached(() => {
    if (isLoading || !hasMoreProducts) {
      return;
    }

    isLoading = true;
    fetchProducts(currentPage + 1)
      .then((productsHtml) => {
        if (productsHtml !== '') {
          currentPage += 1;
          catalogItems.insertAdjacentHTML('beforeend', productsHtml);
          hasMoreProducts = categoryHasMoreProducts(catalogItems, currentPage, DEFAULT_ITEMS_PER_PAGE);
        } else {
          hasMoreProducts = false;
        }
      })
      .catch(console.error)
      .finally(() => {
        isLoading = false;
      });
  });
}
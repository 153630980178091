import debounce from './debounce';

const DEFAULT_AUTO_SCROLL_TIMEOUT = 5000;

function initCarousel(carouselElem) {
  const carouselContent = carouselElem.querySelector('.carousel__content');
  const carouselContentWidth = carouselContent.offsetWidth;
  const carouselItems = carouselContent.querySelector('.carousel__items');
  const carouselItemsMap = Array.from(carouselItems.querySelectorAll('.carousel__item')).reduce((res, item, index) => {
    const { imageId } = item.dataset;

    if (imageId != null) {
      res[imageId] = index;
    }

    return res;
  }, {});

  const scrollLeftButton = carouselElem.querySelector('.carousel__control.carousel__control--left');
  const scrollRightButton = carouselElem.querySelector('.carousel__control.carousel__control--right');

  const {
    carouselAutoScroll: optionAutoScroll = false,
    carouselAutoScrollTimeout: optionAutoScrollTimeout = DEFAULT_AUTO_SCROLL_TIMEOUT
  } = carouselElem.dataset;

  const amountOfItems = carouselItems.children.length;
  let autoScrollTimeout = 0;
  let autoScrollSkip = true;
  let amountOfVisibleItems = 0;
  let minOffset = 0;

  let currentOffset = 0;

  function calibrate() {
    amountOfVisibleItems = Math.round(carouselContentWidth / carouselItems.children[0].offsetWidth);
    minOffset = amountOfVisibleItems - amountOfItems;
    setOffset(currentOffset);
  }

  function setOffset(offset) {
    currentOffset = Math.min(0, Math.max(minOffset, offset));
    carouselItems.style.transform = `translate(${currentOffset * 100}%,0)`;

    scrollLeftButton.classList.toggle('carousel__control--hidden', currentOffset >= 0);
    scrollRightButton.classList.toggle('carousel__control--hidden', -1 * (currentOffset - amountOfVisibleItems) >= amountOfItems);
  }

  function scrollLeft() {
    autoScrollSkip = true;
    setOffset(currentOffset + 1);
  }

  function scrollRight() {
    autoScrollSkip = true;
    setOffset(currentOffset - 1);
  }

  function scrollToId(id) {
    const offset = -1 * carouselItemsMap[id];

    if (offset != null && offset !== currentOffset) {
      setOffset(offset);
    }
  }

  function autoScroll(direction, timeout) {
    const scrollTimeout = typeof timeout === 'string' ? parseInt(timeout) : timeout;

    function scroll() {
      if (!autoScrollSkip) {
        const nextSlide = (currentOffset + (direction === 'left' ? -1 : 1)) % amountOfItems;
        setOffset(nextSlide);
      }

      autoScrollSkip = false;
      autoScrollTimeout = setTimeout(scroll, scrollTimeout);
    }

    scroll();
  }

  scrollLeftButton.addEventListener('click', scrollLeft);
  scrollRightButton.addEventListener('click', scrollRight);

  window.addEventListener('resize', debounce(calibrate, 500));

  if (optionAutoScroll === 'left' || optionAutoScroll === 'right') {
    autoScroll(optionAutoScroll, optionAutoScrollTimeout);
  }

  calibrate();

  return {
    setOffset,
    scrollLeft,
    scrollRight,
    scrollToId
  };
}

export default function init() {
  window.carousels = [];

  for (const carouselElem of document.querySelectorAll('.carousel')) {
    window.carousels.push(initCarousel(carouselElem));
  }
}
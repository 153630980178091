export default function init() {
  const photosVariantsElements = document.querySelector('.product__photos-variants');
  const photosCarousel = window.carousels && window.carousels[0];

  if (!photosVariantsElements || !photosCarousel) {
    return false;
  }

  const photosVariantsItems = photosVariantsElements.querySelectorAll('.product__photos-variants-item');

  for (let itemIndex = 0; itemIndex < photosVariantsItems.length; itemIndex++) {
    photosVariantsItems[itemIndex].addEventListener('click', (event) => {
      event.stopPropagation();

      photosCarousel.setOffset(-1 * itemIndex);
    });
  }
}